import React from "react";

function NFTs(id) {
  return (
    <div className="imgbg">
      {id.id == 1 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/1.png").default}
          alt=""
        />
      ) : null}
      {id.id == 2 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/2.png").default}
          alt=""
        />
      ) : null}
      {id.id == 3 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/3.png").default}
          alt=""
        />
      ) : null}
      {id.id == 4 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/4.png").default}
          alt=""
        />
      ) : null}
      {id.id == 5 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/5.png").default}
          alt=""
        />
      ) : null}
      {id.id == 6 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/6.png").default}
          alt=""
        />
      ) : null}
      {id.id == 7 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/7.png").default}
          alt=""
        />
      ) : null}
      {id.id == 8 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/8.png").default}
          alt=""
        />
      ) : null}
      {id.id == 9 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/9.png").default}
          alt=""
        />
      ) : null}
      {id.id == 10 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/10.png").default}
          alt=""
        />
      ) : null}
      {id.id == 11 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/11.png").default}
          alt=""
        />
      ) : null}
      {id.id == 12 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/12.png").default}
          alt=""
        />
      ) : null}
      {id.id == 13 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/13.png").default}
          alt=""
        />
      ) : null}
      {id.id == 14 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/14.png").default}
          alt=""
        />
      ) : null}
      {id.id == 15 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/15.png").default}
          alt=""
        />
      ) : null}
      {id.id == 16 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/16.png").default}
          alt=""
        />
      ) : null}
      {id.id == 17 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/17.png").default}
          alt=""
        />
      ) : null}
      {id.id == 18 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/18.png").default}
          alt=""
        />
      ) : null}
      {id.id == 19 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/19.png").default}
          alt=""
        />
      ) : null}
      {id.id == 20 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/20.png").default}
          alt=""
        />
      ) : null}
      {id.id == 21 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/21.png").default}
          alt=""
        />
      ) : null}
      {id.id == 22 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/22.png").default}
          alt=""
        />
      ) : null}
      {id.id == 23 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/23.png").default}
          alt=""
        />
      ) : null}
      {id.id == 24 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/24.png").default}
          alt=""
        />
      ) : null}
      {id.id == 25 ? (
        <img
          className="img-fluid logo"
          src={require("../assets/nft/25.PNG").default}
          alt=""
        />
      ) : null}
    </div>
  );
}

export default NFTs;
