import { useEffect } from "react";
import useState from "react-usestateref";
import { Container, Row, Col } from "react-bootstrap";
import Web3 from "web3";
import abi from "./abi.json";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import NFTs from "./NFTs";

function Mint() {
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(null);
  const [nftId, setNftId, nftIdRef] = useState(1);
  const [idTotalMinted, setIdTotalMinted, idTotalMintedRef] = useState(null);

  const changeNFTId = (newId) => {
    setNftId(newId);
    checkIdBalance();
    // console.log(nftIdRef.current);
  };

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);

    const ins = new web3.eth.Contract(
      abi,
      "0xb1A9e0d011EBF08dbA306D78EE8FB7BDD341FBDF"
    );

    setInstance(ins);

    if (window.ethereum) {
      const getTotalMinted = async () => {
        var tokenCount = await ins.methods.tokenCount().call();
        var idMintCount = await ins.methods
          .idMintedBalance(nftIdRef.current)
          .call();
        setIdTotalMinted(idMintCount);
        setTotalMinted(tokenCount);
      };
      getTotalMinted();
    }
  }, []);

  const checkIdBalance = async () => {
    if (instance) {
      var idMintCount = await instance.methods
        .idMintedBalance(nftIdRef.current)
        .call();
      setIdTotalMinted(idMintCount);
    }
  };

  async function requestAccount() {
    console.log("Requesting account...");

    // ❌ Check if Meta Mask Extension exists
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAdd(accounts[0]);
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      window.open("https://metamask.app.link/dapp/mint.mdtripp.com/");
      alert("Meta Mask not detected");
    }
  }

  const mint = async () => {
    if (add) {
      if (instance) {
        console.log(
          await instance.methods.mint(nftIdRef.current, mintNumber).send({
            from: add,
            value: (
              (await instance.methods.price().call()) * mintNumber
            ).toString(),
          })
        );
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 3) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <div className="mint-page">
      <Container className="mt-3">
        <Row>
          <Col className="text-center" lg="12">
            <div className="">
              <img
                style={{ height: "100px" }}
                className="img-fluid"
                src={require("../assets/img/logo.png").default}
                alt=""
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container
        className="mintcon"
        style={{ marginTop: "10rem", marginBottom: "2rem" }}
      >
        <Row>
          <Col className="d-flex justify-content-center" lg="6">
            <div className="">
              <NFTs id={nftIdRef.current} />
            </div>
          </Col>
          <Col className="rmt d-flex justify-content-center" lg="6">
            <div className="">
              <div className=" text-center rmt">
                <div className="">
                  <div className=" text-center rmt">
                    <div onClick={requestAccount} className="btn cusbtn">
                      <span className="font-weight-bold text-uppercase">
                        {add ? add.substring(0, 15) : "Connect Wallet"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <p className="font-weight-bold">Select NFT id and mint</p>
                  <select
                    className="form-control"
                    onChange={(event) => changeNFTId(event.target.value)}
                    value={nftId}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                  </select>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <div className="mx-1">
                    <AiFillMinusCircle color="red" onClick={minus} size={60} />
                  </div>
                  <div className="display-number d-flex justify-content-center align-items-center">
                    <span className="number">{mintNumber}</span>
                  </div>
                  <div className="mx-1">
                    <AiFillPlusCircle color="green" onClick={plus} size={60} />
                  </div>
                </div>
                <div className="text-center mt-3">
                  <button
                    onClick={mint}
                    className="mintbutton btn  text-uppercase"
                  >
                    Mint Now
                  </button>
                </div>
                <div className="text-center mt-4">
                  <h3 className="font-weight-bold bfont">
                    {idTotalMintedRef.current} / 100
                  </h3>
                </div>
                <div className="text-center mt-4">
                  <p className="font-weight-bold">Per NFT Price : 50 Matic</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Mint;
